<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Dudas</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">

              <v-row class="mb-4 mt-4">

                <v-col cols="12" class="mb-4">
                  <v-file-input label="Subir nuevo archivo de dudas"
                                @change="handleFileUpload($event)"></v-file-input>
                  <div align="center">
                    <v-btn type="button" @click="submitForm()" color="primary" class="mt-4">
                      Guardar archivo
                    </v-btn>
                  </div>

                </v-col>
                <v-col v-if="dataFiles.length > 0" cols="3" class="mt-4" v-for="(files, idx2) in dataFiles"
                       :key="idx2">
                  <h4 style="text-align: center">{{ files.name }} </h4>
                  <div style="justify-content: center; display: flex">
                    <v-btn type="button" @click="downloadFile(files.urlFile)" color="primary" class="mt-4">
                      Descargar Archivo
                    </v-btn>
                  </div>


                </v-col>
              </v-row>

      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import FileInputEdit from "@/components/FileInputEdit.vue";
import {URL_API_IMAGES} from "@/store/config";

export default {
  name: "DetailDoubts",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      documentComplete:null,
      fileTmp:null,
      fileSend:null,
      documentGenerate:null,
      dataFiles:[],
      documentGenerateStatus:false,
      id: null,
      formatosId:null,
      data: {
        licitacionesId:"",
        file: "",
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    FileInputEdit,
    CheckButtons,
    FileInput,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
    await this.findAllFiles();
  },
  methods: {
    ...mapActions('doubts', ['updateDoubts', 'findOneDoubts','generateHtmlFormat','downloadFile','findAllFilesDoubts','createDoubtsFiles']),
    ...mapActions('tenders', ['findOneTenders']),
    async findAllFiles(){
      this.dataFiles = []
      const response = await this.findAllFilesDoubts(this.tenderId);
      this.dataFiles = response;

    },
    async submitForm() {
      if (!this.data.file){
        this.sweetAlertWarning('Campo archivo es necesario');
        return
      }
      this.data.licitacionesId = this.tenderId;
      this.overlay = true;
      const response = await this.createDoubtsFiles(this.data);
      if (!response.error) {
        this.data.file = '';
        await this.findAllFiles();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    handleFileUpload(event) {
      this.data.file = null;
      this.data.file = event;

    },
    async downloadFile(data) {
      try {
        const url = `${URL_API_IMAGES}${data}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },

    async findMarketOne() {
      const response = await this.findOneDoubts({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.fileTmp = response.dudas_url;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired(){
      if (!this.fileSend){
        this.sweetAlertWarning('Campo documento es necesario');
        return false
      }
      
      return true

    },



    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming(){
      this.sweetAlertWarning('Proximamente')
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
