<template>
  <div class="custom-input-file d-flex align-center justify-center">
    <div v-if="show" class="fadeIn">
      <iframe :src="url" style="width: 100%;" />
      <v-icon @click="closeImage" id="icon-close">fas fa-times</v-icon>
    </div>
    <div v-if="!show" style="position:relative;" class="d-flex flex-column align-center fadeIn">
      <label for="inputImage" id="inputImageLabel">
        <v-icon class="icon-arrow">fas fa-upload</v-icon>
      </label>
      <input @change="printImage" type="file" class="input-file" id="inputImage" ref="fileImage">
      <p class="mt-6">Arrastra y suelta una imagen para subirla</p>
      <v-btn class="mt-3" style="width: 230px;" color="primary">
        <label style="width: 230px; height: 36px; padding-top: 11px; cursor:pointer;" for="inputImage">Seleccionar imagen</label>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props:{
    showImage: Boolean,
    tenderId: Number,
    formatosId: Number,
    file: [File, String],
    fileTmp: [File, String],
  },
  data() {
    return {
      url: null,
      iconBase64: '',
      show: null
    }
  },
  methods: {
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async printImage(e) {
      const file = this.$refs.fileImage.files[0];
      const file_convert = await this.getBase64(file);
      file_convert.onload = (event) => {
        this.url = event.target.result;
        this.show = true;
      };
      this.file = file;
      this.passImageFile(file);
    },
    closeImage() {
      this.url = null;
      this.show = false;
      this.file = '';
      this.passImageFile('')
    },
    passImageFile(file) {
      this.$emit('getImageFile', file);
    },
  }
}
</script>

<style scoped>
/*#fileInput{*/
/*  width: 400px;*/
/*  height: 400px;*/
/*  background: #ff5252;*/
/*  border: none;*/
/*}*/
.custom-input-file {
  /*background-color: transparent;*/
  /*color: #fff;*/
  /*cursor: pointer;*/
  /*font-size: 18px;*/
  /*font-weight: bold;*/
  /*margin: 0 auto 0;*/
  min-height: 400px;
  overflow: hidden;
  /*padding: 10px;*/
  position: relative;
  text-align: center;
  width: 100%;
}

.custom-input-file .input-file {
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  cursor: pointer;
  /*font-size: 100px;*/
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  /*position: absolute;*/
  /*right: -1000px;*/
  /*top: -1000px;*/
}

.icon-arrow {
  font-size: 50px;
  background: #f2f2f2;
  padding: 50px;
  border-radius: 999px;
  cursor: pointer;
}

#inputImageLabel {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#icon-close {
  position: absolute;
  cursor: pointer;
  right: 0px;
  color: #FF5252;
}
</style>